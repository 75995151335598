import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { 
  Button, 
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography 
} from '@material-ui/core';
import Layout from '../../components/Layout';
import meche from '../../assets/images/meche.svg';
import gift_card from "../../assets/images/0900-CarteCadeau-01.png";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: "flex-end",
      flexDirection: "row",
      alignItems: "flex-start",
    },
  },
  picture: {
    width: "100%",
  },
  col_2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      flexDirection: "column",
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
    },
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    alignContent: "center",
    justifyContent: "center",
    margin: `${theme.spacing(0)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('lg')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      minWidth: theme.spacing(12),
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: "start",
      minWidth: theme.spacing(11),
    },
    [theme.breakpoints.down('sm')]: {
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
      flexDirection: "row",
      alignItems: "center",
      maxWidth: "100%",
    },
  },
  heading_name: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    alignContent: "center",
    padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`,
    writingMode: "vertical-rl",
    textOrientation: "mixed",
    textAlign: "center",
    [theme.breakpoints.down('lg')]: {
    },
    [theme.breakpoints.down('md')]: {
      "& h2": {
        fontSize: theme.spacing(4),
      },
      "& h3": {
        fontSize: theme.spacing(3.5),
      },  
    },
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      writingMode: "initial",
      textOrientation: "initial",
      order: 2,
      alignItems: "flex-start",
      "& h2": {
        fontSize: theme.spacing(3.75),
      },
      "& h3": {
        fontSize: theme.spacing(3.25),
      },  
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: "flex-start",
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      "& h2": {
        fontSize: theme.spacing(2.75),
      },
      "& h3": {
        fontSize: theme.spacing(2.25),
      },  
    },
  },
  pricing: {
    [theme.breakpoints.down('md')]: {
      height: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      order: 1,
      padding: `${theme.spacing(0)}px ${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
      height: theme.spacing(7.5),
    },
  },
  heading_price_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "stretch",  
  },
  heading_price: {
    padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(.5)}px ${theme.spacing(0)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(0)}px`,
    }
  },
  heading_oldPrice: {
    textDecoration: "line-through",
    opacity: .5,
  },
  badge: {
    position: "absolute",
    zIndex: 1,
    top: "1%",
    right: "1%",
    borderRadius: 100,
    height: `${theme.spacing(5)}px`,
    width:`${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { 
      height: `${theme.spacing(5)}px`,
      bottom: "87%",
      left: "75%",    }
  },
  badge_text: {
    position: "absolute",
    margin: `${theme.spacing(2.5)}px`,
    transform: "translate(-50%,-50%) ",
    verticalAlign: "middle",
    textAlign: "center",
  },
  badge_discount: {
    backgroundColor: theme.palette.orange.main,
  },
  badge_new: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    fontStyle: "italic",
  },
  heading_separator: {
    width: "3.5vw",
    [theme.breakpoints.down('sm')]: { 
      width: "5vw",
      margin: `${theme.spacing(0)}px ${theme.spacing(0)}px`
    },
    [theme.breakpoints.down('xs')]: { 
      width: "7.5vw",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    textAlign: "start",
      "& ul": {
        textAlign: "start",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
        paddingLeft: theme.spacing(3),
        listStyleType: "disc"
      },
    },
  warning: { 
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(6)}px`,
  },
  button_return: {
    textDecoration: ` underline 1px solid ${theme.palette.primary.main}`,

    margin: `0 0 0 ${theme.spacing(4)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(4)}px 0 0 0`,
    },
  },
  buttons_container: {
    display: "flex", 
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
}));

const GiftCard = () => {
  const classes = useStyles();
  const amounts = [15,20,30,40,45,50,60,80,120,140];

  const [price, setPrice] = React.useState('15');
  // const [delivery, setDelivery] = React.useState('papier');

  // const handleChangeDelivery  = (event) => {
  //   setDelivery(event.target.value);
  // };
  const handleChangePrice = (event) => {
    setPrice(event.target.value);
  };

  return (
    <Layout pageName="Carte cadeau">
      <Grid 
        container 
        spacing={0} 
        direction="row"
        alignItems="start"
        justifyContent="center"
        className={classes.root}
      >
        <Grid item xs={12} sm={3} md={5} lg={5} className={classes.col_1}>
          <img
            src={gift_card}
            alt={"illustration carte cadeau"}
            className={classes.picture}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={7} className={classes.col_2}>
        <div className={classes.heading}>
          <div className={classes.heading_name}>
            <Typography 
              variant="h3"
              variantMapping={{h3: "h2"}} 
            >
              Carte cadeau
            </Typography>
          </div>
          <div className={classes.pricing}>
            <img className={classes.heading_separator} src={meche} alt="separator" />
            <div className={classes.heading_price_container}>
              <Typography 
                variant="subtitle1" 
                className={classes.heading_price}
              >
                {`${price} €`}
              </Typography>
            </div>
            <img className={classes.heading_separator} src={meche} alt="separator" />
          </div>
        </div>
        <div className={classes.content}>
        <Typography variant="body1">
          Pour le plaisir d'offrir une création <i>Surboom</i> tout en laissant la liberté de choix pour la personne que tu veux gâter&#160;! 
          <br />
          <strong>La carte est envoyée en version papier.</strong>
          <br />
          Si tu souhaites envoyer la carte directement à la personne de ton choix,
          n'oublie pas de cocher la case “Utiliser une adresse de livraison différente" qui te permettra 
          d'indiquer son adresse dans la zone “Adressse de livraison".
          Tu peux aussi personnaliser la carte en laissant un mot que je copierai avec ma plus belle écriture 😇.
        </Typography>
        <p />
        <Typography variant="body2">Conditions</Typography>
        <Typography>
          <ul>
            <li>carte valable 1 an</li> 
            <li>valable pour un achat sur le site ou une commande personnalisée</li>
            <li>de 15 € à 140 €</li>
            <li>utilisable en une fois</li>
          </ul>
        </Typography>
        <FormControl className={classes.form} required>
          <Typography variant="h5">
            Quel montant ?
          </Typography>
          <Select
            labelId="amount-simple-select-label"
            id="amount-simple-select"
            value={price}
            label="Montant *"
            onChange={handleChangePrice}
          >
            {amounts.map((amount) => <MenuItem value={amount}>{amount}</MenuItem>)}
          </Select>
          </FormControl>
          {/* <FormControl className={classes.form}>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="papier"
            name="radio-buttons-group"
            value={delivery}
            onChange={handleChangeDelivery}
          >
            <Typography variant="h5">
                Quel forme d'envoi ?
              </Typography>
            <FormControlLabel value="papier" control={<Radio />} label="papier" />
            <FormControlLabel value="numérique" control={<Radio />} label="numérique" />
          </RadioGroup>
        </FormControl> */}
            <div className={classes.warning}>
              <Typography variant="h5">
                Je rencontre un problème technique pour autoriser l'achat de la carte via le site. 
              </Typography>
              <Typography variant="body1">  
                Je peux néanmoins vous envoyer un <strong>lien de paiement paypal sécurisé</strong> si vous souhaiter acheter une carte cadeau.
                <br />
                Il vous suffit de m'envoyer un mail avec le montant voulu, ainsi que vos coordonnées pour l'envoi de la carte.
            </Typography>
            </div>
          <div className={classes.buttons_container}>
            <Button 
              variant="contained"
              href="mailto:contact@surboom.fr"
              target="_blank" 
              rel="noreferrer"
            >
              Obtenir un lien de paiement sécurisé
            </Button>
            <Button 
              className={clsx(classes.button, classes.button_return)}
              // variant="outlined"
              href="/"
            >
              Retourner à l'accueil
            </Button>
          </div>
        </div>        
      </Grid>
    </Grid>
  </Layout>
  )
}
export default GiftCard;
